import { browser, dev } from "$app/environment";
import type { BrowserOptions } from "@amplitude/analytics-types";
import * as amplitude from "@amplitude/analytics-browser";

const log = (message: string) => {
  if (dev) console.log(message);
};

const createAnalyticsService = () => {
  log("Creating Analytics Service");

  return {
    initialize: async (amplitude_api_key: string) => {
      if (!browser) return;
      const url = window.location;
      const options: BrowserOptions = {
        transport: "beacon",
        defaultTracking: false,
        logLevel: dev ? amplitude.Types.LogLevel.Verbose : amplitude.Types.LogLevel.Warn,
        serverZone: "US",
        serverUrl: `${url.protocol}//${url.host}/amplitude`,
        userId: undefined,
        optOut: false,
      };

      log("Initializing Amplitude as Anonymous");
      amplitude.init(amplitude_api_key, options);
    },
    deleteAmplitudeCookies: () => {
      if (!browser) return;

      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        while (cookie.charAt(0) === " ") {
          cookie = cookie.substring(1);
        }
        if (cookie.startsWith("AMP")) {
          const cookieName = cookie.split("=")[0];
          document.cookie = cookieName + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
      }
    },
  };
};

export const analyticsService = createAnalyticsService();
